<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgUserGroupAdd ref="dlgCommon" :visible="visible" @close="dlgClose"></DlgUserGroupAdd>
        <div class="pa-3">
            <v-toolbar>
            <v-toolbar-title>User Group List</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
            </v-toolbar>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark class="primary mr-2" @click="refreshDataTable"><v-icon>mdi-refresh</v-icon></v-btn>         
        <v-btn icon dark class="mb-2 success" @click="addRecord">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        </v-card-title>
        <v-card-text>

        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-text>

        <v-data-table
        v-model="selected"  
        ref="vuetable"
        item-key="created_date"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"    
        @click:row="openDetail"
        ></v-data-table>
        </v-card>
        </div>

    </v-card>

</template>
<script>
//import axios from 'axios';
import DlgMessage from '@/components/DlgMessage'
import ApUserService from "@/services/ApUserService"
import DlgUserGroupAdd from "@/components/DlgUserGroupAdd";
export default {
    components:{DlgMessage,DlgUserGroupAdd},
    data() {
        return{
            //breadcrumb
            items: [
              {text:'User',disabled: false,href:'/users',},
              {text:'Group',disabled: false,href:'/users/groups',},
              {text:'List',disabled: false,href:'#',},
              ],
            selected: [],
            tblHeaders:[
                { text:"Name", value:"group_name" },
                { text:"Description", value:"group_description" },
                { text:"Start Date", value:"start_date" },
                { text:"End Date", value:"end_date" },
                { text:"Status", value:"status" },
            ],
            tblBody:[],
            tblSearch:'',
            loadingFlag : false,
            errorFlag : false,
            visible:false,
            form:{
                merchant_id:0,
                login_id:'',
            },

        }
    },
    async created() {
      this.refreshDataTable();
    },
    mounted(){
        
    },
    methods:{
            async refreshDataTable(){
                this.loadingFlag = true
                try{
                    ApUserService.list_user_group(this.form).then((res) => {
                        //console.log(res);
                        this.tblBody = res.data.data;
                        this.loadingFlag = false;
                        this.errorFlag = false;
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error','User Group',e.response.data.error_message);
                        this.loadingFlag = false
                        this.errorFlag = true;
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error','User Group','Unknown Error');
                  
                }           
            },
			openDetail(obj){
				//console.log(obj.admin_group_id)
				this.$router.push({ name: 'ug-details', 
					params: { 
						admin_group_id: obj.admin_group_id,
						enc_group_name: btoa(obj.group_name),
					} 
				})

			},

            addRecord(){
                this.visible = true;
            },
            dlgClose(){
                this.visible = false;
                if(!this.errorFlag)
                    this.refreshDataTable();
            },
    }

}
</script>